<template>
  <el-dialog custom-class="custom-dialog" title="" :visible.sync="dialogVisible" :close-on-click-modal="false"
    append-to-body width="1200px" top="0" @close="closeDialog">
    <div class="">
      <div class="tabBut">
        <!-- <template v-for="item in detailsItem.seleArr">
					<el-button @click="detailsItem.seleId=item.value"
						:type='item.value==detailsItem.seleId?"primary":""'>{{item.label}}</el-button>
				</template> -->
        <el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息
        </el-button>
        <el-button v-show="dioDetailsItem.sosTypeName == '安全回路报警' || dioDetailsItem.sosTypeName == '电梯运行异常告警'"
          @click="detailsItem.seleId=2" :type='2 == detailsItem.seleId ? "primary" : ""'>运行参数</el-button>
        <el-button @click="detailsItem.seleId=3" :type='3==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
        <el-button v-if="dioDetailsItem.voiceRescordDTOS.length > 0 || dioDetailsItem.eleSmsRescordDTOS.length > 0"
          @click="detailsItem.seleId=4" :type='4==detailsItem.seleId ? "primary" : ""'>报警通知</el-button>
      </div>
      <div style="height: 55vh;margin-top: 10px;" class="scrollElement"
        :class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
        <alarmMessage v-if="detailsItem.seleId==1" :dioData="dioDetailsItem" />
        <operational v-else-if="detailsItem.seleId==2" :dioData="dioDetailsItem" />
        <process v-else-if="detailsItem.seleId==3" :dioData="dioDetailsItem" />
        <AlarmNotice v-else :dioData="dioDetailsItem" />
      </div>
      <span slot="footer" class="dialog-footer"
        style="display: flex;align-items: center;justify-content: center;margin-top: 20px;">
        <div v-if="detailsItem.seleId==1 && dioDetailsItem.submitType === 0">
          <template v-if="
          	dioDetailsItem.sosType == 95 ||
          	dioDetailsItem.sosType == 0 ||
          	dioDetailsItem.sosType == 10 ||
          	dioDetailsItem.sosType == 7 ||
          	dioDetailsItem.sosType == 3 ||
          	dioDetailsItem.sosType == 333 ||
          	dioDetailsItem.sosType == 4 ||
          	dioDetailsItem.sosType == 5 ||
          	dioDetailsItem.sosType == 11 ||
          	dioDetailsItem.sosType == 12 ||
          	dioDetailsItem.sosType == 13 ||
            dioDetailsItem.sosType == 96 ||
            dioDetailsItem.sosType == 1 ||
            dioDetailsItem.sosType == 2 ||
            dioDetailsItem.sosType == 14 ||
            dioDetailsItem.sosType == 999 ||
            dioDetailsItem.sosType == 98 ||
            dioDetailsItem.sosType == 21 ||
            dioDetailsItem.sosType == 20 ||
            dioDetailsItem.sosType == 19 ||
            dioDetailsItem.sosType == 15 ||
            dioDetailsItem.sosType == 109 ||
            dioDetailsItem.sosType == 22
          ">
            <el-button :disabled="disabled" size="mini" type="primary" @click="sosClick(1)"
              v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 98">
              <span>人为触发(无故障)</span>
              <p style="margin: 10px 0 0 0;">确认关闭</p>
            </el-button>
            <el-button :disabled="disabled" size="mini" type="primary" @click="sosClick(6)"
              v-if="dioDetailsItem.sosType == 13">
              <span>确认</span>
              <p style="margin: 10px 0 0 0;">正常报警非误报</p>
            </el-button>
            <el-button size="mini" type="info"
              v-if="dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 22&&dioDetailsItem.sosType != 109&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 15&& dioDetailsItem.sosType != 98"
              @click="sosClick(2)" :disabled="disabled">
              <span>电梯故障(无困人)</span>
              <p style="margin: 10px 0 0 0;">派发维修工单</p>
            </el-button>
            <el-button size="mini" type="warning"
              v-if="dioDetailsItem.sosType != 22&&dioDetailsItem.sosType != 109&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 19 &&dioDetailsItem.sosType != 20 && dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&& dioDetailsItem.sosType != 96"
              @click="sosClick(3)" :disabled="disabled">
              <span>困人</span>
              <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
            </el-button>
            <el-button class="errorSos" size="mini"
              v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&& dioDetailsItem.sosType != 98"
              @click="sosClick(5)" :disabled="disabled">
              <span>误报</span>
              <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
            </el-button>
          </template>
        </div>
      </span>
    </div>
    <div class="null"></div>
  </el-dialog>
</template>
<script>
  import alarmMessage from '../../bjgl/bjjl/components/alarmMessage'
  import operational from '../../bjgl/bjjl/components/operational'
  import process from '../../bjgl/bjjl/components/process'
  import AlarmNotice from '../../bjgl/bjjl/components/alarmNotice.vue'
  export default {
    props: {
      detailsItem: Object,
      initDioDetailsItem: Object,
    },
    data() {
      return {
        dialogVisible: true,
        dioDetailsItem: this.initDioDetailsItem,
        disabled: false,
        typeDate: [],
        // stateList: [{
        //   value: 1,
        //   label: '待处理'
        // }, {
        //   value: 2,
        //   label: '已处理'
        // }, {
        //   value: 3,
        //   label: '进行中'
        // }, {
        //   value: 4,
        //   label: '已完成'
        // }],
      }
    },
    components: {
      alarmMessage,
      operational,
      process,
      AlarmNotice
    },
    methods: {
      closeDialog() {
        this.$emit('closeDialog')
      },
      // 详情操作按钮
      sosClick(type) {
        this.disabled = true
        if (this.dioDetailsItem.submitType === 0) {
          this.dioDetailsItem.sosStatus = 2
          this.$http
            .post("/api/ele/app/sos/submit", {
              id: this.dioDetailsItem.id,
              submitType: type,
              sosTime: this.dioDetailsItem.sosTime
            })
            .then((res) => {
              if (res.data.success) {
                // this.btnLoading = false
                if (type == 5) {
                  this.$confirm('确认误报成功，是否删除该报警误报记录？', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确认',
                    type: 'warning'
                  }).then(() => {
                    console.log(333)
                    this.$http
                      .post("/api/ele/web/sos/removeSosInfo", {
                        id: this.dioDetailsItem.id,
                        sosTime: this.dioDetailsItem.sosTime,
                      }).then((res) => {
                        console.log(666)
                        if (res.data.code == 200) {
                          this.closeDialog()
                          this.$message.success(res.data.msg)
                        } else {
                          this.$message.error(res.data.msg)
                        }
                      })
                  }).catch(() => {
                    this.closeDialog()
                  })
                } else {
                  this.$message.success(res.data.msg)
                }
                // this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
              // this.closeDialog()
              this.onRefer()
              // this.showDetails(this.tableData.find(item => item.id === this.dioDetailsItem.id))
            })
        }
      },
      // // 获取类型
      // getType(type) {
      //   let obj
      //   let arr = []
      //   arr = this.typeDate.SOS_TYPE
      //   arr.forEach((qs) => {
      //     console.log(qs.value, type, 666)
      //     if (qs.value == type) {
      //       obj = qs.label
      //     }
      //   })
      //   return obj
      // },
    },
    created() {
      console.log(this.dioDetailsItem, this.dioDetailsItem.sosStatus, this.dioDetailsItem.sosType, 5555)
      // this.dioDetailsItem.sosStatusName = this.stateList.find(item => item.value === this.dioDetailsItem.sosStatus) ? this.stateList.find(item => item.value === this.dioDetailsItem.sosStatus)
      //   .label : ''
      // this.dioDetailsItem.sosTypeName = this.getType( this.dioDetailsItem.sosType)
      // this.$http
      //   .post(`/api/system/web/dict/dictionary?codes=SOS_TYPE`)
      //   .then((res) => {
      //     if (res.data.success) {
      //       this.typeDate = res.data.data
      //     }
      //   })
    }
  }
</script>

<style lang="scss" scoped>
  .scrollElement {
    margin: 20px 0 0 0;
    // height: 72vh;
    overflow-y: scroll;
    padding-right: 20px
  }

  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }

  .null {
    height: 90px;
  }

  .tabBut {
    text-align: center;
  }
</style>
