import { render, staticRenderFns } from "./elevator-warning-detail.vue?vue&type=template&id=0ba20956&scoped=true&"
import script from "./elevator-warning-detail.vue?vue&type=script&lang=js&"
export * from "./elevator-warning-detail.vue?vue&type=script&lang=js&"
import style0 from "./elevator-warning-detail.vue?vue&type=style&index=0&id=0ba20956&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba20956",
  null
  
)

export default component.exports